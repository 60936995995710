import { number } from 'prop-types'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  
  isAuthenticated: false,
  isLoading: true,
  companyId: 0,
  userType: 0,
  departmentId: 0,
  designationId: 0,
  employeeId: 0,
  userId: '',
  userName: '',
  firstName: '',
  lastName: '',
  officialEmail: '',
  mobileNo: '',
  roleId: 0,
  companies: [],
  companyLogo: '',

}
