import { USERTOKEN } from '../constants/localstorage'
import { URL } from './URL'
import { ReS } from 'src/services/utility'
import axios from 'axios'

// Side Bar Menu API Service
export async function GetAllMenus() {
  const token = localStorage.getItem(USERTOKEN)

  try {
    const response = await fetch(`${URL}/menus`, {
      method: 'get',

      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })

    if (response.status === 200 || response.status === 201) {
      return await response.json()
    } else if (response.status === 401) {
      throw new Error('Unauthorized access')
    } else if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (e) {
    throw e
  }
}

// Functional Attribute List API
export async function GETtIERMASTER(companyId, projectId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/attribute/${companyId}/${projectId}`, {
      method: `get`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
    // return await response.json()
  } catch (e) {
    throw e
  }
}

// Show Project list API
export async function getProjectMaster(companyId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/project/${companyId}`, {
      method: `get`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
    // return await response.json()
  } catch (e) {
    throw e
  }
}

// Project Listing for user API
export async function getProjectforTaskDescribe(companyId, userId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/getall/${companyId}/${userId}`, {
      method: `get`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
    // return await response.json()
  } catch (e) {
    throw e
  }
}

// project mapp get all project
export async function projectmaptable(employeeId) {
  const token = localStorage.getItem(`USERTOKEN`)
  try {
    const response = await fetch(`${URL}/project/mapping/employee/getall/${employeeId}`, {
      method: `get`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
    // return await response.json()
  } catch (e) {
    throw e
  }
}

// get all company - Not Used
export async function getallCompany() {
  const token = localStorage.getItem(`USERTOKEN`)
  try {
    const response = await fetch(`${URL}/companies`, {
      method: `get`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
    // return await response.json()
  } catch (e) {
    throw e
  }
}

// Create Functional Variable API
export async function addVariableForTiers(body, companyId, attributeId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/variable/${companyId}/${attributeId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

export async function addTierMaster(body, companyId, projectId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/attribute/${companyId}/${projectId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

//addtechnicalvariable
export async function addtechVariableForTiers(body) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/variable/technical/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

//update variables
export async function updateVariableForTiers(body, companyId, variableId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/variable/update/${companyId}/${variableId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {
    throw err
  }
}

// Update Function API
export async function updateTierMaster(body, companyId, projectId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/attribute/update/${companyId}/${projectId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {
    throw err
  }
}

export async function changeEmployee(body, employeeId) {
  const token = localStorage.getItem(USERTOKEN)

  try {
    const response = await fetch(`${URL}/employee/update/${employeeId}`, {
      method: 'PUT',

      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },

      body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {
    throw err
  }
}

//updatevariables
export async function updatetechnicalVariableForTiers(body, variableId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/variable/technical/update/${variableId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {
    throw err
  }
}

//get roleTable
export async function roleTable(companyId, departmentId, Des_Id) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/role/${companyId}/${departmentId}/${Des_Id}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

//Organization Level dropdown

// export async function organizationLeveldrop() {
//   const token = localStorage.getItem(USERTOKEN)
//   try {
//     const response = await fetch(`${URL}/orglevels`, {
//       method: 'get',
//       headers: {
//         'Content-Type': 'application/json',
//         Authorization: token,
//       },
//     })
//     if (response.status === 200) {
//       return await response.json()
//     } else if (response.status === 401) {
//       return await response.json()
//     }
//     if (response.status === 400 || response.status === 404) {
//       return await response.json()
//     } else {
//       var errorResponse = await response.json()
//       throw new Error(errorResponse.error)
//     }
//   } catch (err) {}
// }

//Create Role

export async function addRole(body, companyId, departmentId, designationId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/role/${companyId}/${departmentId}/${designationId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

//update role
export async function updateRole(body, companyId, departmentId, designationId, roleId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(
      `${URL}/role/${companyId}/${departmentId}/${designationId}/${roleId}`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify(body),
      },
    )
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

//delete role

export async function roleDelete(companyId, departmentId, designationId, roleId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(
      `${URL}/role/${companyId}/${departmentId}/${designationId}/${roleId}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      },
    )
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {
    throw err
  }
}

export async function TechnicalMappingDelete(attributeId, employeeId, teachId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(
      `${URL}/employee/attribute/${attributeId}/${employeeId}/${teachId}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      },
    )
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {
    throw err
  }
}

export async function projectMasterDelete(companyId, projectId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/project/${companyId}/${projectId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {
    throw err
  }
}

//create country

export async function addCountry(body) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/country/add`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

//update country

export async function updateCountry(body, countryId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/location/${countryId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

//table country
export async function countryTable() {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/countries`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// delete country

export async function countryDelete(countryId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/${countryId}`, {
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {
    throw err
  }
}

//create area

export async function addArea(body) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/area/add`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

//update area

export async function areaUpdate(body, countryId, stateId, cityId, areaId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/location/${countryId}/${stateId}/${cityId}/${areaId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

//table area
export async function areaTable() {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/areas`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// delete area

export async function areaDelete(countryId, stateId, cityId, areaId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/${countryId}/${stateId}/${cityId}/${areaId}`, {
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {
    throw err
  }
}

// get all cities by state

export async function citybyStates(countryId, stateId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/cities/${countryId}/${stateId}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// get all state by counrty

export async function statebycountries(countryId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/states/${countryId}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// create state

export async function addstate(body) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/state/add`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

//update state

export async function stateUpdate(body, countryId, stateId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/location/${countryId}/${stateId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

//table state
export async function stateTable() {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/states`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// delete state

export async function stateDelete(countryId, stateId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/state/delete/${countryId}/${stateId}`, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {
    throw err
  }
}

// create city

export async function addcity(body) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/city/add`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

//update city

export async function cityUpdate(body, countryId, stateId, cityId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/location/${countryId}/${stateId}/${cityId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

//table city
export async function cityTable() {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/cities`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// delete city

export async function cityDelete(countryId, stateId, cityId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/${countryId}/${stateId}/${cityId}`, {
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401 || response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {
    throw err
  }
}

// functionality attribute delete

export async function attributeDelete(companyId, projectId, attributeId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(
      `${URL}/attribute/delete/${companyId}/${projectId}/${attributeId}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      },
    )
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {
    throw err
  }
}

// technical attribute delete

export async function technicalattributeDelete(attributeId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/attribute/technical/delete/${attributeId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {
    throw err
  }
}

// get all Project

export async function projectdropdown(companyId) {
  const token = localStorage.getItem(USERTOKEN)

  try {
    const response = await fetch(`${URL}/project/${companyId}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })

    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

export async function ProjectTeamAPI(companyId, projectId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/teams/${companyId}/${projectId}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// create user Account

export async function adduseraccount(body) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    // const response = await fetch(`${URL}/company/user/add`, {
    const response = await fetch(`${URL}/user/register`, {
      method: 'POST',

      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },

      body: JSON.stringify(body),
    })

    if (response.status === 201) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }

    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// employee dropdown for user Account Creation

// export async function employeedrop(departmentId, designationId) {
//   const token = localStorage.getItem(USERTOKEN)
//   try {
//     const response = await fetch(

//       `${URL}/employees/filter/getAll/${departmentId}/${designationId}/${true}`,
//       // `${URL}/employees/filter/getAll?departmentId=${departmentId}&designationId=${designationId}&user=true`,

//       {
//         method: 'post',
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: token,
//         },
//       },
//     )
//     if (response.status === 200) {
//       return await response.json()
//     } else if (response.status === 401) {
//       return await response.json()
//     }
//     if (response.status === 400 || response.status === 404) {
//       return await response.json()
//     } else {
//       var errorResponse = await response.json()
//       throw new Error(errorResponse.error)
//     }
//   } catch (err) {}
// }

export async function employeedropFilter(companyId, departmentId, designationId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(
      `${URL}/employee/filter/${companyId}/${departmentId}/${designationId}`,

      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      },
    )
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// User Account Details

export async function userTable(companyId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/users/${companyId}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }

    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// designation dropdown

export async function designationdrop(companyId, departmentId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/designation/${companyId}/${departmentId}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// department dropdown

export async function departmentdrop(companyId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/departments/${companyId}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

//craete Emp
export async function addEmployee(body) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/company/employee/add`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

export async function addDepartment(body) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/department`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

//craete designation
export async function addDesignation(body, companyId, departmentId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    // const response = await fetch(`${URL}/company/designation/add`, {
    const response = await fetch(`${URL}/designation/${companyId}/${departmentId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401 || response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

//craete Holiday
export async function addHoliday(body) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/holiday/add`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401 || response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// Post Activity Work

export async function CreateActivity(body) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/create/activity`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401 || response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// Get Activity Work

export async function GetActivity(body) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/getall/activity`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401 || response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// Update Activity Work

export async function UpdateActivity(body, id) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/activity/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401 || response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// Delete Activity Work
export async function DeleteActivity(data) {
  const token = localStorage.getItem(USERTOKEN)

  try {
    const response = await fetch(`${URL}/activity/${data}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      // body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401 || response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

export async function projectTeamDelete(projectId, userId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/teams/employee/delete/${projectId}/${userId}`, {
      method: 'Delete',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404 || response.status === 405) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {
    throw err
  }
}

// Project map delete

export async function projectmapdelete(employeeId, projectId, companyId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(
      `${URL}/project/mapping/delete/${employeeId}/${projectId}/${companyId}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      },
    )
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {
    throw err
  }
}

// functional variable delete

export async function FuncVariableDelete(variableId, TierId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    // const response = await fetch(`${URL}/variable/delete/${companyId}/${tierId}/${variableId}`, {
    const response = await fetch(`${URL}/variable/delete/${variableId}/${TierId}`, {
      method: 'Delete',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404 || response.status === 409) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {
    throw err
  }
}

// parentdeparment dropdown

export async function parentdrop(companyId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/departments/${companyId}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// functional employee by project

export async function empbyproject(projectId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/project/employees/getAll/${projectId}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

export async function empbyprojectNew(projectId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/employee/project/filter/${projectId}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// functional attribute dropdown

export async function fuctattridrop(projectId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/attributes/${projectId}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

export async function showFuncAttrAPI(companyId, id) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/attribute/${companyId}/${id}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

export async function getVariableParentDropdownAPINew(
  body,
  projectId,
  attributeId,
  tierId,
  parentTierId,
  parentVariableId,
) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(
      `${URL}/variable/functional/parent/${projectId}/${attributeId}/${tierId}/${parentTierId}`,
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify(body),
      },
    )
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}
export async function getVariableParentDropdownAPI(
  companyId,
  projectId,
  attributeId,
  tierId,
  parentTierId,
  parentVariableId,
) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(
      `${URL}/variable/parent/${companyId}/${projectId}/${attributeId}/${tierId}/${parentTierId}/${parentVariableId}`,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      },
    )
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

export async function getFirstVariableParentDropdownAPI(companyId, projectId, attributeId, tierId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(
      `${URL}/variable/firstparent/${companyId}/${projectId}/${attributeId}/${tierId}`,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      },
    )
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// functional unmapped attribute for employee

export async function fucunmappedtattridrop(projectId, employeeId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/attributes/employee/filter/${projectId}/${employeeId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// technical attribute dropdown

export async function technicalattributeGet() {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/technical/attribute/getAll`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

export async function technicalvariablefirstParentGet(attributeId, tierId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(
      `${URL}/variables/technical/firstparent/${attributeId}/${tierId}`,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      },
    )
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

export async function technicalvariabletechnicalParentGet(
  attributeId,
  tierId,
  parentTierId,
  parentVariableId,
) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(
      `${URL}/variable/technical/parent/${attributeId}/${tierId}/${parentTierId}/${parentVariableId}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      },
    )
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

export async function techattridrop() {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/attribute/technical/getAll`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// technical unmapped attribute dropdown

export async function technicalattributePost(body, companyId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/attribute/technical/create/${companyId}`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

export async function technicalattributePostAPINew(
  body,
  attributeIdTech,
  tierIdTech,
  parentTierId,
  parentVariableId,
) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(
      `${URL}/variable/technical/parent/${attributeIdTech}/${tierIdTech}/${parentTierId}/${parentVariableId}`,
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify(body),
      },
    )
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

export async function technicalattributePostAPINewFilter(
  body,
  attributeIdTech,
  tierIdTech,
  parentTierId,
  parentVariableId,
) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(
      `${URL}/variable/get/technical/child/${attributeIdTech}/${tierIdTech}/${parentTierId}/${parentVariableId}`,
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify(body),
      },
    )
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

export async function techunmappedattridrop(employeeId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/attributes/technical/employee/filter/${employeeId}`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

export async function ShowProjectAPI(companyId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/project/${companyId}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// employee dropdown

export async function employeegetall(companyId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/employees/${companyId}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

export async function employeegetallbyAttri(attributeId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/employees/getallemployee/${attributeId}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// Create Attribute Mapping

export async function addAttributeMap(body) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/attributes/mapping/employee`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// Create Technical Attribute Mapping

export async function addtechnicalAttributeMap(body) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/employee/attributemapping`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// Create Project

export async function addProject(body, companyId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/project/${companyId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// update Project
export async function updateProject(body, companyId, projectId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/project/${companyId}/${projectId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

//table Attribute Mapping
export async function mapTable() {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/attributes/functional/mapping/getAll`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

//table technical Attribute Mapping
export async function mapTable1(attributeId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/employees/getallattrtibute/${attributeId}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// project map => get project
export async function projectbyProjectmap(employeeId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/teams/employee/get/project/${employeeId}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// update department
export async function updatedepartment(body, departmentId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    // const response = await fetch(`${URL}/department/${companyId}/${departmentId}`, {
    const response = await fetch(`${URL}/department/${departmentId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {
    throw err
  }
}

// update Team Creation
export async function UpdateProjectTeamAPI(body, companyId, projectId, teamId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    // const response = await fetch(`${URL}/department/${companyId}/${departmentId}`, {
    const response = await fetch(`${URL}/team/${companyId}/${projectId}/${teamId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {
    throw err
  }
}
// update department
export async function designationUpdate(body, companyId, departmentId, designationId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(
      `${URL}/designation/${companyId}/${departmentId}/${designationId}`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify(body),
      },
    )
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401 || response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {
    throw err
  }
}

// update holiday
export async function holidayUpdate(body, companyId, holidayId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/holiday/${companyId}/${holidayId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401 || response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {
    throw err
  }
}

export async function technicalattributeupdate(body) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/attribute/technical/update/tiervalue`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401 || response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {
    throw err
  }
}

// PLANNING TABLE
export async function planningtableview(employeeId, projectId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/task/description/getAll/${employeeId}/${projectId}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// user Active

export async function ActivateUser(userId) {
  const token = localStorage.getItem(USERTOKEN)

  try {
    // const response = await fetch(`${URL}/user/status/update?userId=${userId}&activate=true`, {
    const response = await fetch(`${URL}/user/delete?userId=${userId}&active=true`, {
      // const response = await fetch(`${URL}/user/${userId}`, {

      method: 'Delete',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      // body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// user Deactive

export async function DeActivateUser(userId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    // const response = await fetch(`${URL}/user/status/update?userId=${userId}&activate=false`, {
    const response = await fetch(`${URL}/user/delete?userId=${userId}&active=false`, {
      method: 'Delete',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      // body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// Employee === reporting to

export async function repotingtoemp(departmentId, designationId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(
      `${URL}/employees/filter/getAll?departmentId=${departmentId}&designationId=${designationId}&user=false`,
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      },
    )
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// vARIABLE TABLE
export async function GETALLVARIABLE(projectId, attributeId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/variables/get/${projectId}/${attributeId}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// Technical vARIABLE TABLE
export async function GETALLTECHVARIABLE(attributeId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/variables/technical/get/${attributeId}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// dashboard count

export async function dashboardCount() {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/employee/dashboard/task/getAll`, {
      // const response = await fetch(`${URL}/employee/pplanning/task/getAll`, {

      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// Team leader find API
export async function dashboardTeamLeadFindAPI() {
  const token = localStorage.getItem(USERTOKEN)

  try {
    const response = await fetch(`${URL}/employee/find/lead`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// add Today Task Planning
export async function addTodayTask(body) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    // const response = await fetch(`${URL}/employee/planning/update/plannedDuration`, {
    // const response = await fetch(`${URL}/employee/planning/update/plannedDuration`, {
    const response = await fetch(`${URL}/employee/planning/plannedDuration/update`, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// add Update Task Status
export async function todayTaskUpdate(body) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/employee/planning/update/status`, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// team Task Report API
export async function TeamTaskReportGenerate(body) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/team/planned/task`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// start task report
// Self Task API Calling

export async function TaskReportGenerate(body) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/employee/project/planned/task`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// Self Task Report API
export async function generateWeekMonthTaskReport(body) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/employee/project/weekly/planned/task`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// end task report
// Team Task Report API
export async function TeamGenerateWeekMonthTaskReport(body) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/team/project/weekly/planned/task`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// Report generate
export async function generateReport(body) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/employee/planning/today/report/completed`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// Report generate - Team Today Appraisal report
export async function TeamAppgenerateReport(body) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/team/report/completed`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

export async function generateWeeklyAppraiselReport(body) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/employee/project/get/weekly/planning`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

export async function TeamgenerateWeeklyAppraiselReport(body) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/team/project/weekly/report/completed`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// designation getall
export async function getalldesignation(companyId, departmentId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/designation/${companyId}/${departmentId}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401 || response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// organization Level Reporting office DropDown GET API
export async function organizationLevelShowCompany() {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/orglevels`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// organization Level Save API
export async function organizationLevelSave(body) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/orglevel/add`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// organization Level Update API
export async function organizationLevelUpdate(body, orgId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/orglevel/${orgId}`, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// export async function LoginPageAPI(body) {
//   const token = localStorage.getItem(USERTOKEN)
//   try {
//     const response = await fetch(`${URL}/company/user/login`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         Authorization: token,
//       },
//       body: JSON.stringify(body),
//     })
//     if (response.status === 200) {
//       return await response.json()
//     } else if (response.status === 401 || response.status === 400 || response.status === 404) {
//       return await response.json()
//     } else {
//       var errorResponse = await response.json()
//       throw new Error(errorResponse.error)
//     }
//   } catch (err) {}
// }

// organization Type Save API

export async function organizationTypeSave(body) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/orglevel/add`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// organization Type Tabel GET API
export async function organizationTypeShowCompany() {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/orgtypes`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// business Type Tabel GET API
export async function businessTypeTable() {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/businesstypes`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// Company Tabel GET API
export async function CompanyTable() {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/companies`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// employee Tabel GET API
export async function employeeTable() {
  const token = localStorage.getItem(USERTOKEN)
  try {
    // const response = await fetch(`${URL}/employees/${companyId}`, {
    const response = await fetch(`${URL}/employees/all`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

export async function AllemployeeTable() {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/employees/all`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

//Project mapping employee dropdown GET API
export async function ProjectMappingEmployeeDrop(companyId) {
  const token = localStorage.getItem(USERTOKEN)

  try {
    const response = await fetch(`${URL}/employees/${companyId}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

//Project mapping PROJECT dropdown GET API
export async function ProjectMappingprojectDrop(employeeId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/teams/employee/get/project/${employeeId}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

//Project mapping SAVE API
export async function ProjectMappingSava(body, employeeId, companyId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/project/mapping/employee/${employeeId}/${companyId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

//Task Description All project dropdown GET API
export async function TaskDescriptionAllProject(employeeId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/project/mapping/employee/getall/${employeeId}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// selected project wise employee data
export async function SelectEmployeeByProject(projectId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/teams/employees/planning/filter/${projectId}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// selected project wise employee data
export async function TeamPlanAssignEmpAPI(projectId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/team/subordinates/${projectId}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

//Task Description Function attr dropdown GET API

export async function TaskDescriptionFunctionattr(id, companyId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/attributes/${companyId}/${id}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

export async function TaskDescriptionFunctionattrNew(companyId, projectId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/attribute/${companyId}/${projectId}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

//Task Description Function attr dropdown GET API based on project and employee
export async function TaskDescriptionFunctionattrByEmployee(id) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/attribute/employee/filter/${id}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}
//Task Description Function attr Variable  dropdown GET API
export async function TaskDescriptionTechnicalattr() {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/attribute/technical/getAll`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

//Project mapping SAVE API
export async function TaskDescriptionSave(body) {
  const token = localStorage.getItem(USERTOKEN)

  try {
    const response = await fetch(`${URL}/task/description/project/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })

    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }

    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

export async function SelfTaskDescriptionSave(body) {
  const token = localStorage.getItem(USERTOKEN)

  try {
    const response = await fetch(`${URL}/employee/planning/task/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })

    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }

    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

//addtechnicalvariable
export async function dashboardCountpending(body) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/employee/planning/task/pending/getAll`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

export async function TeamSaveAPI(body, companyId, projectId, departmentId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/team/${companyId}/${projectId}/${departmentId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

//department table

export async function getalldepartment() {
  const token = localStorage.getItem(USERTOKEN)
  try {
    // const response = await fetch(`${URL}/departments/${companyId}`, {
    const response = await fetch(`${URL}/department`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

export async function showAllEmployeeWithoutProjectAPI(projectId, departmentId) {
  const token = localStorage.getItem(USERTOKEN)

  try {
    // const response = await fetch(`${URL}/departments/${companyId}`, {
    const response = await fetch(`${URL}/teams/employees/filter/${projectId}/${departmentId}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

//HOLIDAY table
export async function getallHOLIDAY(companyId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/holidays/${companyId}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

//user table
export async function getalluser() {
  const token = localStorage.getItem(USERTOKEN)
  try {
    // const response = await fetch(`${URL}/users/${companyId}`, {
    // const response = await fetch(`${URL}/users/getall`, {
    // const response = await fetch(`${URL}/company/users`, {
    const response = await fetch(`${URL}/companies/users`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

//holiday type

export async function getholidaytype(companyId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/holiday/types/${companyId}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// department table delee
export async function departdelete(departmentId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    // const response = await fetch(`${URL}/department/delete/${companyId}/${departmentId}`, {
    const response = await fetch(`${URL}/department/${departmentId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

export async function employeeDelete(employeeId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/employee/delete/${employeeId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// holiday table delee
export async function holidaydelete(companyId, holidayId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/holiday/delete/${companyId}/${holidayId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// designation table delee
export async function designdelete(companyId, departmentId, designationId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(
      `${URL}/designation/${companyId}/${departmentId}/${designationId}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      },
    )
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}
export async function descriptiondashboardNew(body, employeeId, projectIdData) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    // const response = await fetch(`${URL}/task/description/get/${employeeId}/${projectId}`, {
    const response = await fetch(
      `${URL}/multitask/description/get/${employeeId}/${projectIdData}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify(body),
      },
    )
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

export async function technicalattributePostAPINewNew(
  body,
  attributeIdTech,
  tierIdTech,
  parentTierId,
  parentVariableId,
) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(
      `${URL}/variable/technical/byparent/${attributeIdTech}/${tierIdTech}/${parentTierId}/${parentVariableId}`,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify(body),
      },
    )
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

//addtechnicalvariable
export async function descriptiondashboard(body, employeeId, projectId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/task/description/get/${employeeId}/${projectId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// organization Level Save API
export async function taskUpdate(body) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/employee/planning/task/get/plannedDuration`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// organization Level Save API

export async function todaytaskdata(body) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/employee/planning/task/get/plannedTask`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

export async function teamTodaytaskdata() {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/employee/getall/subordinates`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      // body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// export async function todaytaskdataNew(body) {
//   const token = localStorage.getItem(USERTOKEN)
//   try {
//     const response = await fetch(`${URL}/employee/planning/task/get/plannedTask`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         Authorization: token,
//       },
//       body: JSON.stringify(body),
//     })
//     if (response.status === 200) {
//       return await response.json()
//     } else if (response.status === 401) {
//       return await response.json()
//     }
//     if (response.status === 400 || response.status === 404) {
//       return await response.json()
//     } else {
//       var errorResponse = await response.json()
//       throw new Error(errorResponse.error)
//     }
//   } catch (err) {}
// }

export async function downloadAppraisalTodayPdf(body, header) {
  const token = localStorage.getItem(USERTOKEN)
  const response = await fetch(`${URL}/download/pdf`, {
    method: `POST`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify({ data: body, header: header }),
  })
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json()
  } else if (response.status === 401 || response.status === 400) {
    return await response.json()
  } else {
    let errorResponse = await response.json()
    throw new Error(errorResponse.error)
  }
}

export async function getBloodGroup() {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/bloodgroup/getall`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

export async function TaskDescriptionUpdate(body, projectId, employeeId, taskId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(
      `${URL}/task/description/update/${projectId}/${employeeId}/${taskId}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify(body),
      },
    )
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

export async function forgotpwdVerify(body) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/user/reset/auth`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

export async function TaskDescriptionDeletion(projectId, employeeId, taskId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(
      `${URL}/task/description/delete/${projectId}/${employeeId}/${taskId}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        // body: JSON.stringify(body),
      },
    )
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

export async function confirmpwdVerify(body) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/user/reset/verify`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

export async function updatePassword(body) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(
      `${URL}/user/reset/password
    `,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify(body),
      },
    )
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

export async function FunctionalAttributeMappingDeletion(employeeId, projectId, attributeId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(
      `${URL}/attributes/employee/delete/${employeeId}/${projectId}/${attributeId}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        // body: JSON.stringify(body),
      },
    )
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

export async function StateList(countryId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/states/${countryId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      // body: JSON.stringify(body),
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

// employee based filter mapped technical attribute

export async function technicalAttributeBymappedemp(employeeId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/attributes/technical/filter/mapped/${employeeId}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

export async function TechnicalVariableDropAPI(employeeId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/attributes/getallattributes/${employeeId}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

export async function getProjectByEmployeeId(employeeId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/project/mapping/employee/getall/${employeeId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    return ReS(response)
  } catch (err) {
    console.log(err)
  }
}

export async function getAllIncompletedPlanning(body) {
  const token = localStorage.getItem(USERTOKEN)

  try {
    const response = await fetch(`${URL}/employee/project/planning/incompleted`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    return ReS(response)
  } catch (err) {
    console.log(err)
  }
}

export async function getAllIncompletedPlanningNew(body) {
  const token = localStorage.getItem(USERTOKEN)

  try {
    const response = await fetch(`${URL}/employee/project/planning/incompleted`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    return ReS(response)
  } catch (err) {
    console.log(err)
  }
}

export async function ReScheduleProject(body) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/employee/planning/rescheduling`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    return ReS(response)
  } catch (err) {
    console.log(err)
  }
}

// Technical Variable

export async function TechnicalVariableDelete(VariableId, tierId) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/variable/technical/delete/${VariableId}/${tierId}`, {
      method: 'Delete',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {
    console.log(err)
  }
}

export async function getUnscheduledreport(body) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/employee/planning/task/unscheduled`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    return ReS(response)
  } catch (err) {
    console.log(err)
  }
}

export async function getScheduledreport(body) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/employee/planning/task/scheduled`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    return ReS(response)
  } catch (err) {
    console.log(err)
  }
}

export async function getcompletedStatusReport(body) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/employee/planning/task/completed`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    return ReS(response)
  } catch (err) {
    console.log(err)
  }
}

export async function getTotalStatusReport(body) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/employee/planning/task/total`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    return ReS(response)
  } catch (err) {
    console.log(err)
  }
}

export async function getBalanceStatusReport(body) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(`${URL}/employee/planning/task/balance`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    })
    return ReS(response)
  } catch (err) {
    console.log(err)
  }
}

export async function getEmployeeStatusCount(body) {
  const token = localStorage.getItem(USERTOKEN)
  try {
    const response = await fetch(
      `${URL}/employee/planning/status/count/${body.projectId}/${body.employee}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        // body: JSON.stringify(body),
      },
    )
    return ReS(response)
  } catch (err) {
    console.log(err)
  }
}
// Location New API
// Rajesh.V

export async function LocationPincodeAPI(body) {
  // const token = localStorage.getItem(USERTOKEN)
  try {
    // const response = await fetch(`${URL}/company/user/add`, {
    const response = await fetch(
      `https://smaccapi1.kpostindia.com/sMaCcEssOnE/profile/getAddressDetailsUsingPostalCode/`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Authorization: token,
        },
        body: JSON.stringify(body),
      },
    )
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}

export async function teamMembersListAPI() {
  const token = localStorage.getItem(USERTOKEN)

  try {
    const response = await fetch(`${URL}/employee/getall/team/members`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (response.status === 200) {
      return await response.json()
    } else if (response.status === 401) {
      return await response.json()
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json()
    } else {
      var errorResponse = await response.json()
      throw new Error(errorResponse.error)
    }
  } catch (err) {}
}
