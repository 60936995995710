// development
// Local

// Amresh
// export const URL = `http://192.168.2.33:8080/v1`

// Chandru - Local
// export const URL = `http://192.168.2.30:2505/api/v1`

// New Live
export const URL = `https://planningapi.kpost.in/api/v1`

// export const URL = `http://49.207.184.116:8080/v1`

// production
// Live
// export const URL = `https://psapi.kpost.in/v1`

// export const URL = `https://psapi.kpost.in/v1`

// export const URL1 = `http://192.168.1.50:2022/v1`
