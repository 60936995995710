import React from 'react'
import PropTypes from 'prop-types'
import initialStates from './initialStates'
import GlobalReducer from './reducers'
import { technicalattributePost } from 'src/services/ApiServices'

export const GlobalContext = React.createContext()

export default function GlobalProvider({ children }) {
  
  const [state, dispatch] = React.useReducer(GlobalReducer, initialStates)

  return <GlobalContext.Provider value={{ state, dispatch }}>{children}</GlobalContext.Provider>
}

GlobalProvider.propTypes = {
  children: PropTypes.element,
}
GlobalProvider.defaultProps = {
  children: <div></div>,
}
