import moment from 'moment'
import { LOGOUT } from 'src/constants/reducers'
/* eslint-disable valid-typeof */
export const TYPES = {
  STRING: 'string',
  OBJECT: 'object',
  ARRAY: 'array',
}

export function getObjectValue(objectKey, typeofData, ComObject, defaultValue) {
  if (Object.keys(ComObject).indexOf(String(objectKey) >= 0)) {
    if (typeofData === TYPES.ARRAY) {
      if (Array.isArray(ComObject[objectKey])) {
        return ComObject[objectKey]
      } else {
        if (defaultValue) {
          return defaultValue
        } else {
          return []
        }
      }
    } else if (typeofData === TYPES.STRING) {
      if (typeof ComObject[objectKey] === typeofData) {
        return ComObject[objectKey]
      } else {
        return ''
      }
    }
  } else if (defaultValue) {
    return defaultValue
  } else if (typeofData === TYPES.ARRAY) {
    return []
  } else if (typeofData === TYPES.STRING) {
    return ''
  }
}

export function getValue(value, typeOfData, defaultValue) {
  if (typeOfData) {
    if (typeOfData === TYPES.STRING) {
      if (typeof value === TYPES.STRING) {
        return value
      } else {
        if (defaultValue) {
          return defaultValue
        } else {
          return ''
        }
      }
    }
    if (typeOfData === TYPES.ARRAY) {
      if (Array.isArray(value)) {
        return value
      } else {
        if (defaultValue) {
          return defaultValue
        } else {
          return []
        }
      }
    }
    if (typeOfData === TYPES.OBJECT) {
      if (typeof value === TYPES.OBJECT) {
        return value
      } else {
        if (defaultValue) {
          return defaultValue
        } else {
          return null
        }
      }
    }
  } else {
    console.error('Type of Data Field isRequired')
  }
}

export function isNull(value) {
  return value === null || value === undefined || typeof value === 'undefined'
}

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]))
  } catch (e) {
    return null
  }
}

export function isValidBearerToken(token) {
  const decodedJwt = parseJwt(token)
  if (decodedJwt.exp * 1000 < Date.now()) {
    return false
  } else {
    return true
  }
}

export function getMinutesBetweenTwoTimes(startTime, endTime) {
  const getStartTimeFormat = moment(getValue(startTime, TYPES.STRING, ''), 'HH:mm')
  const getEndTimeFormat = moment(getValue(endTime, TYPES.STRING, ''), 'HH:mm')
  const duration = moment.duration(getEndTimeFormat.diff(getStartTimeFormat))

  // duration in minutes
  const minutes = parseInt(duration.asMinutes())

  if (
    minutes === NaN ||
    minutes === undefined ||
    minutes < 0 ||
    getStartTimeFormat == '' ||
    getEndTimeFormat == ''
  ) {
    return 0
  } else {
    return minutes
  }
}

const is24HourFormat = () => {
  var date = new Date(Date.UTC(2012, 11, 12, 3, 0, 0))
  var dateString = date.toLocaleTimeString()
  if (dateString.match(/am|pm/i) || date.toString().match(/am|pm/i)) {
    return false
  } else {
    return true
  }
}

export const ReS = async (res) => {
  const response = await res.json()
  if (res.status === 200) {
    return { ...response, isAuthorized: true }
  } else if (response.status === 401) {
    return { ...response, isAuthorized: false }
  }
  if (res.status === 400 || res.status === 404) {
    return { ...response, isAuthorized: true }
  } else {
    var errorResponse = { ...response, isAuthorized: true }
    throw new Error(errorResponse.error)
  }
}

export const isArray = (value) => {
  try {
    return Array.isArray(value)
  } catch (err) {
    console.error(err)
    return false
  }
}

export const getMaxLengthArray = (...arrays) => {
  const getAllarrays = arrays
  const getValidArrays = []

  let MaxLengthArray = []
  if (isArray(getAllarrays)) {
    for (let i = 0; i < getAllarrays.length; i) {
      if (isArray(getAllarrays[i])) {
        getValidArrays.push(getAllarrays[i])
      }
    }
    for (let i = 0; i < getValidArrays.length; i) {
      if (MaxLengthArray.length < getValidArrays[i].length) {
        MaxLengthArray = getValidArrays[i]
      }
    }
    return MaxLengthArray
  } else {
    return []
  }
}

export const logout = (toast, dispatch, navigate) => {
  toast.warning(`Session Timeout please re-login`)
  localStorage.clear()
  dispatch({
    type: LOGOUT,
  })
  navigate('/login')
}
