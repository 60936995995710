import { USERLOGIN, PROFILE, LOGOUT, COMPANY_CHANGE, COMPANIES } from '../../constants/reducers'
import { USERDATA, USERTOKEN, COMPANY_ID } from '../../constants/localstorage'
import { ID } from '../../constants/localstorage'
import initialStates from '../initialStates'

const GlobalReducer = (state, { type, payload }) => {
  switch (type) {
    case PROFILE:

      // Hide this USERDATA and ID line for logout on refresh

      localStorage.setItem(USERDATA, JSON.stringify(payload))
      localStorage.setItem(USERTOKEN, payload.userToken)
      // localStorage.setItem(USERNAME, payload.userName)
      localStorage.setItem(ID, payload.id)

      return {

        ...state,
        isAuthenticated: true,
        companyId: payload.companyId._id,
        companyName: payload.companyId.companyName,
        userType: payload?.usertype._id,
        departmentId: payload.departmentId,
        designationId: payload.designationId,
        employeeId: payload.employeeId,
        userId: payload._id,
        userName: payload.userName,
        firstName: payload.firstName,
        lastName: payload.lastName,
        officialEmail: payload.officialEmail,
        mobileNo: payload.mobileNo,
        roleId: payload.roleId,
        username:payload.username,
        // companyLogo: payload.companyLogo,
        adminId: "64c8f50ac78e98e5b7b81234"
  
        
      }

    case LOGOUT:
      // Hide this 2 line for logout on refresh
      localStorage.removeItem(USERDATA)
      localStorage.removeItem(USERTOKEN)
      return initialStates

    case COMPANIES:
      return {
        ...state,

        companies: payload.companies,
      }
    case COMPANY_CHANGE:
      localStorage.setItem(COMPANY_ID, payload.companyId)
      // alert(JSON.stringify(payload))
      return {
        ...state,

        companyId: payload.companyId,
        companyLogo: payload.companyLogo,
      }
    default:
      return state
  }
}
export default GlobalReducer
